import React, { useState, useEffect } from "react";

import { Grid, Typography, Divider } from "@pankod/refine-mui";

import { useOne } from "@pankod/refine-core";
import { useParams } from "react-router-dom";
import axios from "utils/axios";
import { normalizeData } from "utils/utils";

// sections
import StepperCmp from "components/StepperCmp";
import UserInfoNonSchool from "./nonschool/UserInfoNonSchool";
import UserInfoSchool from "./school/UserInfoSchool";
import TripInfoNonSchool from "./nonschool/TripInfoNonSchool";
import TripInfoSchool from "./school/TripInfoSchool";
import MandatoryPolicyInfo from "./nonschool/MandatoryPolicyInfo";
import OptionalPolicyInfo from "./nonschool/OptionalPolicyInfo";
import TripRecap from "./nonschool/TripRecap";
import { toast } from "react-toastify";

export default function TripStepperNS() {
  const [user, setUser] = useState(null);
  const [trip, setTrip] = useState(null);
  const [steps, setSteps] = useState([
    {
      label: "Dettagli Utente",
      component:
        user?.role?.name === "bl_scolastico"
          ? UserInfoSchool
          : UserInfoNonSchool,
    },
    {
      label: "Dettagli Viaggio",
      component:
        user?.role?.name === "bl_scolastico"
          ? TripInfoSchool
          : TripInfoNonSchool,
    },
    {
      label: "Polizze Obbligatorie",
      component: MandatoryPolicyInfo,
    },
    {
      label: "Polizze Facoltative",
      component: OptionalPolicyInfo,
    },
    {
      label: "Riepilogo",
      component: TripRecap,
    },
  ]);

  const params = useParams();
  const record = useOne({
    resource: "trips",
    id: params.id,
    metaData: {
      populate: "*",
    },
  });

  useEffect(() => {
    if (!record || trip) return;
    const t = normalizeData(record?.data?.data);
    setTrip(t);
    console.log(t);
    t.insurance_policies.filter((policy) => policy.type === "optional") > 0 &&
      setSteps([
        {
          label: "Dettagli Utente",
          component:
            user?.role?.name === "bl_scolastico"
              ? UserInfoSchool
              : UserInfoNonSchool,
        },
        {
          label: "Dettagli Viaggio",
          component:
            user?.role?.name === "bl_scolastico"
              ? TripInfoSchool
              : TripInfoNonSchool,
        },
        {
          label: "Polizze Obbligatorie",
          component: MandatoryPolicyInfo,
        },
        {
          label: "Polizze Facoltative",
          component: OptionalPolicyInfo,
        },
        {
          label: "Polizze Facoltative",
          component: OptionalPolicyInfo,
        },
        {
          label: "Riepilogo",
          component: TripRecap,
        },
      ]);
  }, [record]);

  useEffect(() => {
    const fetchUser = async () => {
      const { data } = await axios.get("/api/users/me");
      setUser(data);
    };
    fetchUser();
  }, []);

  const onSubmit = async (data) => {
    try {
      let { data: resData } = await axios.post("/api/personal-details", {
        data: {
          ...data,
          user: user.id,
          trip: trip.id,
        },
      });

      if (user.role.name == "bl_nonscolastico") {
        trip.installments.forEach(async (payment) => {
          const { data } = await axios.post("/api/payments", {
            data: {
              user: user.id,
              trip: trip.id,
              description: payment.name,
              sum: payment.price,
              payment_type: payment.type,
              days: payment.days,
              personal_detail: resData.data.id,
            },
          });
        });

        trip.insurance_policies.forEach(async (policy) => {
          const { data } = await axios.post("/api/payments", {
            data: {
              user: user.id,
              trip: trip.id,
              description: policy.name,
              sum: policy.price,
              payment_type: "full",
              personal_detail: resData.data.id,
            },
          });
        });

        let selectedPolicies = (trip.insurance_policies || []).filter(
          (x) =>
            x.type == "optional" && data.optional_insurances?.includes(x.name)
        );
        // remove duplicates
        selectedPolicies = selectedPolicies.filter(
          (thing, index, self) =>
            index === self.findIndex((t) => t.name === thing.name)
        );

        selectedPolicies.forEach(async (policy) => {
          const { data } = await axios.post("/api/payments", {
            data: {
              user: user.id,
              trip: trip.id,
              description: policy.name,
              sum: policy.price,
              payment_type: "full",
              personal_detail: resData.data.id,
            },
          });
        });
      }
      toast.success("I dati sono stati salvati con successo");
      await new Promise((resolve) => setTimeout(resolve, 1000));
      window.location.href =
        user.role.name == "bl_scolastico" ? "/user-attachments" : "/payments";
    } catch (error) {
      toast.error("Si è verificato un errore");
      console.log(error);
    }
  };

  return (
    <>
      <Grid item xs={12} sx={{ mb: 3 }}>
        <Typography variant="h4" gutterBottom component="div">
          Scheda Iscrizione: {trip?.title}
        </Typography>
        <Divider sx={{ mb: 2 }} />
      </Grid>
      {trip?.cancelled && (
        <Grid item xs={12} sx={{ mb: 3 }}>
          <Typography variant="h6" gutterBottom component="div">
            Questo viaggio è stato annullato
          </Typography>
        </Grid>
      )}
      {!trip?.cancelled && (
        <StepperCmp
          steps={steps}
          onSubmit={onSubmit}
          errorMessage="Per favore completa tutti i campi prima di procedere"
          customData={{ user, trip }}
        />
      )}
    </>
  );
}
