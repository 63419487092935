import { useMemo, useState, useEffect } from "react";
import { EditButton, DeleteButton, Stack } from "@pankod/refine-mui";
import {
  Typography,
  useDataGrid,
  DataGrid,
  List,
  IconButton,
} from "@pankod/refine-mui";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";

export default function CompList() {
  const columns = useMemo(
    () => [
      {
        field: "trip_code",
        headerName: "Codice Viaggio",
        flex: 1,
        minWidth: 100,
      },
      { field: "title", headerName: "Nome Viaggio", flex: 1, minWidth: 200 },
      {
        field: "destination",
        headerName: "Destinazione",
        flex: 1,
        minWidth: 200,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              {params.row?.destination?.title}
            </Stack>
          );
        },
      },
      {
        field: "trip_type",
        headerName: "Tipo Viaggio",
        flex: 1,
        minWidth: 200,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              {params.row?.trip_type?.title}
            </Stack>
          );
        },
      },
      {
        field: "package_type",
        headerName: "Tipo Pacchetto",
        flex: 1,
        minWidth: 200,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              {params.row?.package_type?.title}
            </Stack>
          );
        },
      },
      {
        field: "agent",
        headerName: "Agente",
        flex: 1,
        minWidth: 200,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              {params.row?.agent?.first_name +
                " " +
                params.row?.agent?.last_name}
            </Stack>
          );
        },
      },
      {
        field: "airport",
        headerName: "Aeroporto",
        flex: 1,
        minWidth: 200,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              {params.row?.departure_airports
                ?.map((departure_airport) => departure_airport.title)
                .join(", ")}
            </Stack>
          );
        },
      },
      {
        field: "accomodation",
        headerName: "Sistemazione",
        flex: 1,
        minWidth: 200,
        renderCell: function render(params) {
          console.log(params.row?.accommodations);
          return (
            <Stack direction="row" spacing={1}>
              {params.row?.accommodations
                ?.map((accommodation) => accommodation.title)
                .join(", ")}
            </Stack>
          );
        },
      },
      {
        headerName: "Actions",
        field: "actions",
        minWidth: 150,
        renderCell: function render(params) {
          return (
            <Stack direction="row" spacing={1}>
              <Link to={`/trip-view/${params.row.id}`}>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                >
                  <VisibilityIcon />
                </IconButton>
              </Link>
              <EditButton hideText recordItemId={params.row.id} />
              <DeleteButton hideText recordItemId={params.row.id} />
            </Stack>
          );
        },
      },
    ],
    []
  );

  const { dataGridProps, sortingMode, sortModel, onSortModelChange } =
    useDataGrid({
      metaData: {
        populate: "*",
      },
    });

  return (
    <List
      resource="trips"
      createButtonProps={{ size: "small" }}
      headerProps={{
        title: <Typography variant="h5">Viaggi</Typography>,
      }}
    >
      <DataGrid
        {...dataGridProps}
        columns={columns}
        sortingMode={sortingMode}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        autoHeight
      />
    </List>
  );
}
